
.swiper-slide {
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
  }
  
  .swiper-slide-active {
    opacity: 1;
  }
  
  .text-stroke {
    -webkit-text-stroke: 0.1px #000; 
    text-shadow:
      -0.2px -0.2px 0 #000,
       0.2px -0.2px 0 #000,
      -0.2px  0.2px 0 #000,
       0.2px  0.2px 0 #000;
  }

.ombre {
  overflow: hidden;
}

.ombre img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: inherit;

}

.ombre img::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit !important;
}
.ombre::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.0));
  background-color: inherit;
}

