    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: #883C28 !important;
    }

    .custom-checkbox {
      width: 17px;
      height: 17px;
      border: 2px solid #883C28;
      background-color: inherit;
      appearance: none;
      cursor: pointer;
      position: relative;
    }

    .custom-checkbox:checked {
      background-color: inherit;
    }

    .custom-checkbox:checked::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 45%;
      width: 6px;
      height: 12px;
      border: solid #883C28;
      border-width: 0 2px 2px 0;
      transform: translate(-50%, -50%) rotate(45deg);
    }