@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./assets/fonts.css');


body {
    background: #242424;

}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.mainBlock {
    transition: opacity 0.2s ease-in-out;
}

.modal-open {
    opacity: 0;
}

.user-select-none {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}