/* assets/fonts.css */
@font-face {
  font-family: 'Stolzl';
  src: url('./fonts/stolzl_regular.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Stolzl';
  src: url('./fonts/stolzl_regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Stolzl';
  src: url('./fonts/stolzl_light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

/* @font-face {
  font-family: 'Attack Type';
  src: url('./fonts/attacktype-medium.otf') format('opentype');
} */

@font-face {
  font-family: 'Adieu';
  src: url('./fonts/Adieu-Regular.ttf') format('truetype');
}
