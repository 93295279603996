.notification {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 1rem 1rem;
    /* margin-top: 1rem; */
    /* text-align: center; */
    border-radius: 8px;
    width: max-content;
    z-index: 1000;
    transition: opacity 0.5s ease, top 0.5s ease;
    opacity: 0;
    pointer-events: none;
    font-size: 18px;
    border: 1px solid #883C28;
    z-index: 40;
  }
  
  .notification.show {
    opacity: 1;
    top: 1rem;
    pointer-events: auto;
  }
  
  .notification.hide {
    opacity: 0;
    top: 0;
  }
  
  .notification.success {
    background-color: black;
    color: #883C28;
  }
  
  .notification.error {
    background-color: black;
    color: red;
  }
  